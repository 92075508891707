// SASS/SCSS variables
$primary: var(--primary);
$primary-dark: var(--primary-dark);
$link-color: var(--link-color);
$link-color-dark: var(--link-color-dark);
$brand-color: var(--brand-color);
$secondary: var(--secondary);
$secondary-dark: var(--secondary-dark);
$data-table-header-color: var(--data-table-header-color);
$data-table-font-color: var(--data-table-font-color);
$data-table-page-color: var(--data-table-page-color);
$data-table-page-dark-color: var(--data-table-page-dark-color);
$primary-title: var(--primary-title);
$primary-rgba-8: var(--primary-rgba-8);
$primary-rgba-9: var(--primary-rgba-9);

// custom css property
:root {
  --primary: #545cd8;
  --link-color: #545cd8;
  --primary-dark: #2e38cb;
  --link-color-dark: #2e38cb;
  --brand-color: #6437FF;
  --secondary: #6c757d;
  --secondary-dark: #5a6268;
  --data-table-header-color: #FFFFFF;
  --data-table-font-color: #495057;
  --data-table-page-color: #545cd8;
  --data-table-page-dark-color: #2e38cb;
  --primary-title: 18, 21, 78, 0.7;
  --primary-rgba-8: 84,92,216,0.8;
  --primary-rgba-9: 84,92,216,0.9;
}
