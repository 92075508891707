@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .app-main {
        flex: 1 1 auto !important;
    }
    .app-main__outer {
        flex: 1 1 auto !important;
    }
    .app-main__inner {
        flex: 1 1 auto !important;
    }
    .app-inner-layout__content {
        flex: 1 1 auto !important;
    }
    .media-body {
        flex: 1 1 auto !important;
    }
    .header__content {
        flex: 1 1 auto !important;
    }
    .app-header-menu {
        flex: 1 1 auto !important;
    }
    .closed-sidebar .app-sidebar:hover .app-sidebar__inner .metismenu-link {
        text-indent: 0rem !important;
        padding: 0 1.5rem 0 45px;
    }
    .header-dots .icon-wrapper-bg { display: none; }
    .round-bg-gray { background: rgba(0, 0, 0, 0.1) !important; }
}